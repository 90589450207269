@import "@styles/common";

.user-notification {
  position: relative;
  padding: 12px 20px 12px 0;
  font-size: 0.9em;
  line-height: 1.4em;
  cursor: pointer;
  &_unread::after {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    background-color: $orange;
    border-radius: 50%;
    content: "";
  }
  .user-notification-delay {
    color: $gray;
    font-size: 0.8em;
  }
}
