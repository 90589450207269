@import "@styles/common";

.team-member-selector-wrapper {
  .team-member-selector-values {
    @include flex(flex-start);
    @include customScrollbar();

    flex-wrap: wrap;
    max-height: calc(100vh - 350px);
    margin-top: 20px;
    margin-right: -20px;
    padding-right: 15px;
    overflow-y: auto;
    .team-member-label {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
