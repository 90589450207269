@import "@styles/common";

$delete-icon-size: 20px;

.options-selector-wrapper {
  .options-selector-content {
    margin-top: 15px;
    .options-selector-item {
      margin-bottom: 15px;
      .options-selector-item-label {
        margin: 7px 10px;
        color: $gray;
        font-size: $font-size-small;
      }
      .options-selector-item-input {
        @include flex(space-between);

        width: 100%;
        .custom-input {
          width: 100%;
        }
      }
      .options-selector-item-remove {
        width: $delete-icon-size;
        min-width: $delete-icon-size;
        height: $delete-icon-size;
        @include backgroundImage("/assets/files/icons/general/delete.svg");

        margin-left: 10px;
        cursor: pointer;
      }
      .error-message {
        margin-top: 2px;
        margin-left: 5px;
      }
    }
  }
  .option-selector-footer {
    @include flex(flex-end);

    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color;
  }
}
