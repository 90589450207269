@import "@styles/variables.scss";

.error-message {
  position: absolute;
  margin-top: -10px;
  color: $error-field-message;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: right;
}
