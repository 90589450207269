@import "@styles/z-index.scss";

$wrapper-width: 440px;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, medium2);
  width: calc(100% - #{$wrapper-width} + 20px);
  height: 100%;
  background: black;
  opacity: 0.3;
}

.overlay-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: map-get($z-indexes, medium3);
  width: $wrapper-width;
  height: 100%;
  padding: 30px 20px;
  overflow-y: auto;
  background: white;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);

  &::-webkit-scrollbar {
    display: none;
  }
  .overlay-content {
    height: 96vh;
  }
  .overlay-body {
    position: relative;
    min-height: 100%;
  }
}
