@import "@styles/common.scss";

.training-header {
  @include flex();

  max-width: 100%;
  font-weight: normal;
  .training-activities-filter {
    width: 250px;
  }
  .custom-input {
    height: 40px;
    margin-right: 10px;
    font-size: 14px;
  }
  .custom-date-range-picker {
    max-width: 250px;
    margin-right: 10px;
  }
}

.members-list-daterange-picker {
  width: auto;
  margin-right: 0.6em;
}
