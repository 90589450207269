@import "@styles/variables.scss";

$switch-border-radius: 8px;
$switch-black-color: #292929;

.switcher {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 90px;
  height: 40px;
  padding: 3px;
  background-color: $border-color;
  border-radius: $switch-border-radius;
  cursor: pointer;
  &::before {
    position: absolute;
    top: 3px;
    right: 50%;
    left: 3px;
    display: block;
    min-width: calc(50% - 3px);
    height: 34px;
    background-color: $switch-black-color;
    border-radius: $switch-border-radius;
    transition: 300ms all;
    content: "";
  }
  .switcher-value {
    position: relative;
    display: inline-block;
    width: 100%;
    color: $switch-black-color;
    text-align: center;
    transition: 300ms color;
    &_active {
      color: white;
    }
  }
  &.switcher_position-off::before {
    right: 3px;
    left: 50%;
  }
}
