@import "@styles/common";

.training-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1680px;
  min-height: calc(100% - 50px);

  .training-form-card-general,
  .training-form-card-activities-and-projects {
    width: 800px;
    margin: 0 20px 20px 0;

    #activities,
    #projects {
      &.input-line.error {
        margin-bottom: 1em;
      }
      .multi_select_value_item.is_highlight {
        color: white;
        background-color: $red;
        .icon.clear {
          @include backgroundImage("/assets/files/icons/general/clear-white.svg");
        }
      }
    }
  }

  .visibility-block {
    margin-top: 1.5em;
    padding: 1.5em 0;
    border-top: 1px solid $border-color;
  }

  .visibility-header {
    margin-bottom: 1.2em;
    color: #6787d8;
    font-weight: 400;
    font-size: 18px;
  }

  .dates-row {
    margin-bottom: -20px;
  }

  .devider-first {
    margin-top: 30px;
  }

  .training-general-form-divider {
    margin-bottom: 20px;
  }

  .expiration-date-row {
    margin-top: 20px;

    .block:last-child {
      margin-left: 0;
    }

    .column-or-label {
      width: 20px;
      margin: 20px 0 0 0;
      font-size: 14px;
    }

    .column-date {
      width: 140px;
    }

    .column-time {
      width: 140px;
    }
  }

  .expiration-recurring-row {
    justify-content: start;
    height: 40px;
    margin-top: 10px;
    .column-expiration-recurring-label {
      width: 83px;
      padding-top: 7px;
      color: $gray;
      font-size: 12px;
    }

    .expiration-recurring-checkbox-wrapper {
      width: 200px;
    }

    .repeat-every-number-wrapper {
      width: 120px;
      margin-top: 1px;
    }

    .repeat-every-period-select-wrapper {
      width: 140px;
      margin-top: 1px;
    }
  }

  .certificate-name-wrapper {
    margin-top: 15px;
  }

  .cerificate-row {
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 20px;

    .certificate-valid-wrapper {
      width: 120px;
    }

    .certificate-valid-period-select-wrapper {
      width: 140px;
      margin-top: 15px;
    }
  }

  .location-wrapper {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  #is_auto_create_certificate {
    margin-bottom: 20px;
  }
}
