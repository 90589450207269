@import "@styles/common";

.date-time-header {
  font-size: 0.9em;
  .date,
  .start-end-time {
    display: inline-block;
    margin-left: 2em;
  }
  .start-end-time {
    text-transform: lowercase;
  }

  .status {
    display: inline-block;
    margin-left: 0.7em;
    color: $gray;
  }

  .date-icon,
  .start-end-time-icon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
    margin-bottom: 1px;
    vertical-align: sub;
  }
}
