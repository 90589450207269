@import "@styles/variables.scss";

.profile-wrapper {
  position: fixed;
  bottom: 18px;
  left: 210px;
  min-width: 240px;
  max-width: 350px;
  min-height: 200px;
  background: #fff;
  border-radius: 10px 10px 10px 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.11);

  .profile-header-wrapper {
    width: 100%;
    min-height: 80px;
    padding: 20px;
    background: $main-background-dark;
    border-radius: 10px 10px 0 0;
    .profile-header {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .user-name {
        display: -webkit-box;
        margin-bottom: 5px;
        overflow: hidden;
        font-size: 16px;
        word-break: break-all;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .user-role {
        color: $gray;
        font-size: 12px;
      }
    }
  }

  .profile-content-wrapper {
    padding: 0 20px;
    color: $main-color;
    .profile-content {
      .icon {
        flex-shrink: 1;
        width: 20px;
        height: 20px;
      }

      .text {
        padding-left: 5px;
      }

      .user-email {
        display: flex;
        align-items: center;
        height: 70px;
        font-size: 14px;
        border-bottom: 1px solid $border-color;
        .icon.letter {
          flex-shrink: 0;
        }
        .text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .user-logout {
        display: flex;
        align-items: center;
        height: 70px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }
      .general-links {
        border-bottom: 1px solid $border-color;
      }
      .general-link {
        display: block;
        margin: 1.3em 0;
        color: black;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
