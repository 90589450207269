@import "@styles/common";

.render-select {
  label {
    padding-left: 10px;
    color: $gray;
    font-size: $font-size-small;
    line-height: 30px;
  }
  .error-message {
    margin-top: 5px;
  }
}

.custom-select {
  height: 40px;
  .custom-select__control {
    height: 100%;
    border: 1px solid $border-color;
    border-radius: 8px;
    &:hover {
      border-color: $border-color;
      box-shadow: none;
    }
  }
  .custom-select__menu {
    z-index: 10;
  }
  .custom-select__menu-list {
    @include customScrollbar();
  }
  &.disabled {
    > div {
      background: #f2f2f2 !important;
      cursor: default;
    }
  }
  .custom-select__option {
    word-break: break-all;
    &.custom-select__option--is-selected {
      background-color: $blue;
    }
  }
}
