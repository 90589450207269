@import "@styles/common";
@import "@styles/z-index.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, medium3);
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: map-get($z-indexes, high1);
  width: 330px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 18px 35px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  .modal-title {
    margin-bottom: 10px;
    color: $accent-color2;
    font-weight: bold;
    font-size: $font-size-title;
  }
  .modal-body {
    margin-bottom: 20px;
    color: $main-color;
    font-size: $font-size-hint;
    word-break: break-word;
  }
  .modal-buttons {
    width: 100%;
    @include flex(space-between);
    button {
      width: 49%;
      height: 40px;
      padding: 0;
      font-weight: bold;
    }
    .modal-buttons__block-btn {
      width: 100%;
    }
  }
}
