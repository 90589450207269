@import "@styles/common";

.files-block {
  .files-list {
    margin-top: 10px;
  }

  .empty-list {
    padding: 10px 0;
    color: #ddd;
    font-weight: 700;
    font-size: 24px;
    font-family: $global-font-family;
    line-height: 32px;
    text-align: center;
    background-color: rgba(221, 221, 221, 0.2);
    border-radius: 10px;
  }

  .hidden {
    display: none;
  }
}

.file-button-wrapper {
  .hidden {
    display: none;
  }
  .file-button__add {
    padding: 10px !important;
  }

  margin-bottom: 14px;
}
