@import "@styles/common";

.file-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: $global-font-family;
  background: rgba(221, 221, 221, 0.2);
  border-radius: 10px;

  .file-item-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 38px 10px 15px;
    color: #9e9e9e;
    font-size: 12px;

    .left-file-item-header {
      width: 50%;
      word-break: break-all;

      a {
        color: #292929;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        word-break: break-all;
      }
    }

    .right-file-item-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 49%;

      .file-user {
        word-break: break-all;
      }

      .file-devider {
        padding: 0 5px;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 13px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
    }
  }
}
