@import "@styles/common";

.rich-textarea-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  &.disabled {
    color: $gray;
    cursor: not-allowed;
  }

  label {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding-left: 0;
    color: #9e9e9e;
    font-weight: normal;
    font-size: 12px;
    font-style: normal;
    line-height: 30px;
  }
}

:root {
  --ck-border-radius: 10px;
  --ck-color-base-border: #ddd;
}
