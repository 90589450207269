@import "@styles/common";

.location-label-wrapper {
  .location-label {
    display: block;
    margin-top: 0.5em;
    color: $blue;
    .location-label-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
      vertical-align: sub;
    }
  }
}
