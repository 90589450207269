@import "@styles/common";

.custom-date-range-picker {
  @include flex(flex-start, stretch, column);

  margin-bottom: 0;

  input {
    height: 100%;
    padding: 0;
    padding-left: 11px;
  }

  .custom-date-range-picker-wrapper {
    width: 100%;
    height: 40px;
    font-family: $global-font-family;
    &.rs-picker-disabled {
      opacity: 1;
    }
    .rs-picker-toggle {
      height: 100%;
    }
    .rs-picker-toggle-value {
      margin-top: 3px;
      color: $main-color;
      font-size: $font-size-hint;
    }
    .rs-picker-toggle[disabled] {
      background-color: #f3f3f3 !important;
      .rs-picker-toggle-value {
        color: $gray;
      }
    }
  }
}

.rs-picker-date-menu {
  .rs-calendar-time-dropdown-column ul {
    @include customScrollbar();
  }
}
