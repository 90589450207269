@import "@styles/common";

.team-member-label {
  @include flex(space-between);

  .team-member-warning {
    display: none;
  }

  &.no-certificates {
    background-color: #fbdddd;
    .team-member-warning {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      margin-left: 0;
    }
  }

  min-width: 140px;
  height: 50px;
  padding: 5px 16px;
  background-color: rgba($color: $border-color, $alpha: 0.2);
  border-radius: 10px;
  .team-member-label-name {
    color: $main-color;
    font-size: $font-size-hint;
  }
  .team-member-label-position {
    color: $gray;
    font-size: $font-size-small;
    line-height: 20px;
  }
  .icon {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
}
