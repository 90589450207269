@import "@styles/common";

.tooltip {
  z-index: 1000000;
  padding: 10px;
  color: white;
  font-size: 14px;
  background-color: #292929;
  border-radius: 10px;
  opacity: 0.8;

  &.sidebar-tooltip {
    padding: 5px;
    color: #2e3548;
    font-size: $font-size-hint;
    background-color: #fff;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    box-shadow: 0 4px 8px rgba(46, 53, 72, 0.3);
    opacity: 1;
    &::before {
      position: absolute;
      bottom: 0;
      left: -8px;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent transparent #fff transparent;
      border-style: solid;
      border-width: 0 0 7px 10px;
      content: "";
    }
  }
}
