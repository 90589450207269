$z-indexes: (
  // -----low--------
  low1: 1,
  low2: 2,
  low3: 3,
  // -----medium-----
  medium1: 10,
  medium2: 11,
  medium3: 12,
  // ----high--------
  high1: 100,
  high2: 200,
  high3: 300,
  "loading": 10000
);
