@import "@styles/common";

.apply-select-option {
  @include flex(flex-end);

  margin: 10px 10px 0 10px;
  padding: 10px 0;
  border-top: 1px solid $border-color;
  .apply-option {
    margin-left: 25px;
    color: $orange;
  }
  .apply-option,
  .apply-reset-option {
    font-weight: 600;
    font-size: $font-size-normal;
    font-family: $global-font-family;
    cursor: pointer;
    &.disabled {
      color: $gray;
    }
  }
  .apply-reset-option {
    color: $main-color;
  }
}
