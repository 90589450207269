@import "@styles/common";

.search {
  position: relative;
  min-width: 40px;
  font-size: $font-size-hint;
  font-family: $global-font-family;
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 8px;

  .input-search {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 13px 110px 12px 35px;
    border: none;
    border-radius: 8px;
    outline: none;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .search-icon-block {
    position: absolute;
    bottom: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.find {
      top: 9px;
      left: 9px;
    }

    &.delete {
      right: 10px;
      cursor: pointer;
    }
  }

  .search-controls {
    @include flex(flex-start);

    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    padding: 10px;
    .clear-text {
      margin-right: 15px;
      color: $gray;
      cursor: pointer;
    }
    .close-divider {
      height: 100%;
      margin-right: 10px;
      border: 1px solid $gray;
      opacity: 0.2;
    }
    .close-button {
      font-weight: 500;
      font-size: $font-size-normal;
      cursor: pointer;
    }
  }
}
