@import "@styles/common";

.issued-on-row {
  > .column-date {
    width: 50%;
  }
  > .repeat-every-number-wrapper {
    width: 20%;
  }
  > .repeat-every-period-select-wrapper {
    width: 30% !important;
  }
  .rs-picker-toggle {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
}

.certificate-additional-form-archive {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: right;
  @include flex(flex-start, flex-end, column);
  .certificate-additional-form-archive-button {
    width: fit-content;
    margin-top: 20px;
    color: $red;
    font-weight: bold;
    cursor: pointer;
  }
}

.certificate-submit-btn {
  .drop-down-main-button {
    width: 136px;
  }
  .drop-down-elements-wrapper {
    width: 136px;
  }
}

.certificate-form-files-block {
  margin-top: 1.3em;
  .left-files-header {
    color: $main-color;
    font-size: 1em;
  }
  &.files-block .empty-list {
    font-weight: normal;
    font-size: 14px;
  }
  .file-item {
    .file-item-header {
      flex-wrap: wrap;
      .left-file-item-header,
      .right-file-item-header {
        width: 100%;
      }
      .right-file-item-header {
        justify-content: start;
        margin-top: 0.3em;
      }
    }
  }
}
