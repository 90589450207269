@import "@styles/common";

.form-card {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  .form-hint-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    .form-hint {
      margin-bottom: 20px;
      color: $blue;
      font-size: 18px;
    }
  }
  .form-card-row:not(:last-child) {
    margin-bottom: 1em;
    padding-bottom: 1em;
    line-height: 1.8em;
    border-bottom: 1px solid $border-color;
  }
  .form-card-subtitle {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: $font-size-normal;
  }
}
