@import "@styles/variables.scss";

.text-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  &.archive {
    color: $red;
  }

  &.cancel {
    color: $main-color;
  }
}
