@import "@styles/common";

.training-view-member-wrapper {
  display: flex;
  flex-wrap: wrap;

  .training-view-card {
    width: 800px;
    margin: 0 20px 20px 0;
  }
  .training-label-array {
    .label-array-title {
      margin-bottom: 10px;
      color: $gray;
      font-size: $font-size-small;
    }
  }
  .training-recurring {
    font-size: $font-size-small;
  }
  .training-date-info {
    .date-time-header .date {
      margin-left: 0;
    }
  }
  .training-view-description {
    img {
      max-width: 100%;
    }
  }
  .training-status-wrapper {
    @include flex(space-between);

    margin-bottom: 10px;
    font-size: $font-size-hint;
  }
  .training-status-hint {
    color: $gray;
  }
  .training-pass-date-wrapper {
    @include flex(flex-start);
    .training-pass-signature {
      @include flex(flex-start);

      margin-left: 24px;
      .training-pass-signature-title {
        color: $gray;
        font-size: $font-size-small;
      }
      .training-pass-signature-image {
        width: 38px;
        height: 28px;
        margin-left: 5px;
        img {
          width: 100%;
        }
      }
    }
  }
  .training-status-value {
    @include flex();
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &.scheduled {
      color: $blue;
    }
    &.missed {
      color: $red;
    }
    &.completed {
      color: $green;
    }
  }
  .training-title {
    padding-bottom: 10px !important;
    font-weight: bold;
    font-size: 24px;
  }
  .training-member-buttons {
    @include flex();
    .training-member-button {
      @include flex();

      width: 200px;
      height: 40px;
    }
  }
  .training-view-update-info {
    color: $gray;
    font-size: $font-size-small;
  }
}
