@import "@styles/common";

.contact-us-modal-wrapper {
  .modal-title {
    margin-bottom: 0.9em;
    color: $main-color;
  }
  .contact-us-container-cancel-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #f1f1f1;
    border-radius: 10px;
    cursor: pointer;
    @include flex();
    .icon.close {
      width: 20px;
      height: 20px;
    }
  }
}
