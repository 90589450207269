@import "@styles/common.scss";
.drop-down-wrapper {
  position: relative;
  z-index: 1;
  .drop-down-main-button {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 200px;
    height: 40px;
    margin-left: 24px;
    padding: 0 13px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    .arrow-icon {
      position: absolute;
      top: 10px;
      right: 19px;
      width: 10px;
      height: 20px;
      transform: rotate(270deg);
      &.chevron-orange {
        top: 14px;
        right: 15px;
        width: 20px;
        height: 10px;
        transform: rotate(0deg);
      }
    }

    transition: all 0.2s;
    transition-delay: 0.3s;
  }
  &.open {
    .drop-down-main-button.draft {
      background-color: white;
      border-color: white;
      transition-delay: 0s;
    }
    .arrow-icon {
      right: 23px;
      transform: rotate(90deg);

      &.chevron-orange {
        transform: rotate(180deg);
      }
    }
  }
  .drop-down-elements-wrapper {
    position: absolute;
    top: 34px;
    left: 25px;
    z-index: -1;
    width: 198px;
    max-height: 0;
    overflow: hidden;
    background: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    transition: max-height 0.3s ease-out;
    .drop-down-element {
      position: relative;
      padding: 13px 20px;
      color: $orange;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
    }
    .drop-down-element:first-child {
      padding: 19px 30px 13px 20px;
    }
    .drop-down-element:not(:last-child)::after {
      position: absolute;
      top: 57px;
      right: 9px;
      left: 9px;
      height: 1.2px;
      background-color: #ddd;
      content: "";
    }
  }
  &.open .drop-down-elements-wrapper {
    max-height: 300px;
    transition: max-height 0.5s ease-in;
  }
}
