@import "@styles/common";

.training-view-wrapper {
  display: flex;
  flex-wrap: wrap;

  .training-view-column {
    width: 45%;
    min-width: 590px;
    margin: 0 20px 20px 0;
  }

  .training-view-card-general,
  .training-view-card-members {
    width: 100%;
  }
  .training-view-card-members {
    margin-bottom: 20px;
  }
  .training-view-card-general {
    margin-right: 20px;
  }
  .training-view-update-info {
    color: $gray;
    font-size: $font-size-small;
  }
  .training-label-array {
    .label-array-title {
      margin-bottom: 10px;
      color: $gray;
      font-size: $font-size-small;
    }
  }
  .training-recurring {
    font-size: $font-size-small;
  }
  .training-view-description {
    img {
      max-width: 100%;
    }
  }
}
